@import "src/styles/helpers";

.industry {
  position: relative;
  z-index: 1;
  flex: 1;
  padding: 32px;
  min-height: 204px;
  min-width: 245px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid;
  @include setProperty(
    border-color,
    var(--dark-line-shape),
    var(--light-line-shape)
  );
  border-radius: 16px;

  @include media(mobile) {
    min-width: 202px;
  }

  @include media(portrait) {
    min-width: 202px;
    @include setProperty(background-color, var(--dark-bg), var(--light-bg));
  }

  .imageWrapper {
    position: relative;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .image {
      object-fit: contain;
      position: static !important;
      width: auto !important;
      height: auto !important;
    }
  }

  .title {
    font-family: "KharkivTone", sans-serif;
    font-size: 14px;
    font-style: normal;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    line-height: 20px;

    @include media(desktopAll) {
      font-size: 16px;
    }
  }
}
