@import "src/styles/helpers";

.serviceCase {
  margin-top: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: 16px;
  background-color: var(--secondary-50);
  overflow: hidden;

  @include media(landscape) {
    flex-direction: row;
    gap: 0;

    .images {
      flex: 1;
    }
  }

  @include media(desktopAll) {
    margin-top: 40px;
    flex-direction: row;
    gap: 0;

    .images {
      flex: 1;
    }
  }

  .caseLabel {
    position: absolute;
    z-index: 2;
    top: 24px;
    left: 24px;
    height: 28px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--secondary-500);
    background-color: var(--light-bg);
    border-radius: 16px;

    @include media(landscape) {
      left: auto;
      right: 24px;
    }
    @include media(desktopAll) {
      left: auto;
      right: 24px;
    }
  }

  .caseContainer {
    padding: 0 24px 24px;
    order: 1;
    color: var(--secondary-500);

    @include media(landscape) {
      order: 0;
      flex-basis: calc(327px + 48px + 48px);
      padding: 48px;
    }

    @include media(desktopAll) {
      order: 0;
    }

    @include media(desktopSmall) {
      flex-basis: calc(327px + 64px + 64px);
      padding: 48px 64px;
    }

    @include media(desktop) {
      flex-basis: calc(327px + 64px + 64px);
      padding: 48px 64px;
    }

    @include media(desktopBig) {
      flex-basis: calc(480px + 64px + 64px);
      padding: 48px 64px;
    }

    @include media(desktopHuge) {
      flex-basis: calc(480px + 64px + 64px);
      padding: 64px;
    }

    .title {
      font-family: "KharkivTone", sans-serif;
      font-size: 16px;
      line-height: 24px;

      @include media(desktopAll) {
        font-size: 20px;
      }
    }

    .description {
      margin-top: 12px;
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
    }

    .technologies {
      margin-top: var(--margin-md);

      &__title {
        color: var(--secondary-400);
        font-family: Montserrat, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      &__content {
        margin-top: 12px;
        display: flex;
        flex-wrap: wrap;
        gap: var(--margin-md);
      }

      .technology {
        flex-shrink: 0;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-height: 100%;
        }
      }
    }
  }

  .images {
    position: relative;
    z-index: 1;
    height: 226px;
    overflow: hidden;

    @include media(portrait) {
      height: 362px;
    }

    @include media(landscape) {
      height: auto;
    }

    @include media(desktopAll) {
      height: auto;
    }

    .imageWrapper {
      .image {
        object-fit: cover;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}
