@import "src/styles/helpers";

.serviceBlock {
  margin: 64px 0;

  @include media(portrait) {
    margin: 104px 0;
  }

  @include media(landscape) {
    margin: 120px 0;
  }

  @include media(desktopAll) {
    margin: 80px 0;
  }

  .textContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    @include media(landscape) {
      flex-direction: row;
      gap: 80px;
    }

    @include media(desktopAll) {
      flex-direction: row;
      gap: 120px;
    }

    .sectionWrapper {
      flex: 1 0;
    }

    .title {
      max-width: 240px;
      font-family: "KharkivTone", sans-serif;
      font-size: 28px;
      line-height: 36px;

      @include media(desktopAll) {
        max-width: 340px;
        font-size: 40px;
        line-height: 48px;
      }
    }

    .text {
      flex: 1 0;
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 32px;
      @include setProperty(color, var(--secondary-50), var(--secondary-500));
    }
  }

  .servicesList {
    display: flex;
    flex-direction: column;
    gap: 72px;
    margin: 72px 0 0;

    @include media(landscape) {
      gap: 96px;
      margin: 96px 0 0;
    }

    @include media(desktop) {
      gap: 96px;
      margin: 96px 0 0;
    }

    @include media(desktopBig) {
      gap: 104px;
      margin: 104px 0 0;
    }

    @include media(desktopHuge) {
      gap: 104px;
      margin: 104px 0 0;
    }

    .bottomLine {
      height: 1px;
      margin-top: 24px;
      @include setProperty(background, linear-gradient(90deg, rgba(81, 81, 81, 0.3), rgba(188, 188, 188, 0.2), rgba(81, 81, 81, 0.2)), linear-gradient(90deg, rgba(81, 81, 81, 0.05), rgba(188, 188, 188, 0.3), rgba(81, 81, 81, 0.05)));

      @include media(landscape) {
        margin-top: 44px;
      }

      @include media(desktopAll) {
        margin-top: 44px;
      }
    }

    .serviceContainer:last-child .bottomLine {
      display: none;
    }

    @include media(landscape) {
      margin-top: 44px;
    }
  }
}
