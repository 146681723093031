@import "src/styles/helpers";

.industries {
  margin: 64px 0;

  @include media(portrait) {
    margin: 104px 0;
  }

  @include media(landscape) {
    margin: 120px 0;
  }

  @include media(desktopAll) {
    margin: 88px 0;
  }

  .title {
    font-family: "KharkivTone", sans-serif;
    width: 320px;
    font-size: 28px;
    line-height: 36px;

    @include media(desktopAll) {
      font-size: 40px;
      line-height: 48px;
      width: 440px;
    }
  }

  .list {
    margin-top: 48px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    @include media(mobile) {
      grid-template-columns: repeat(auto-fill, minmax(202px, 1fr));
      @include hideScrollbar;
      display: flex;
      flex-wrap: nowrap;
      gap: var(--margin-sm);
      overflow-x: auto;
      overflow-y: hidden;
      margin-right: calc(var(--container-padding-mobile) * -1);
      padding-right: var(--container-padding-mobile);
      margin-left: calc(var(--container-padding-mobile) * -1);
      padding-left: var(--container-padding-mobile);
    }

    @include media(landscape) {
      margin-top: 64px;
    }

    @include media(desktopAll) {
      margin-top: 96px;
    }

    @include media(notMobile) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
      gap: 16px;
    }

    @include media(portrait) {
      grid-template-columns: repeat(auto-fill, minmax(202px, 1fr));
    }
  }
}
