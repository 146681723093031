@import "src/styles/helpers";

.wrapper {
  position: relative;
  z-index: 3;

  .facts {
    position: relative;
    margin: 72px auto 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 108px;
    border: 1px solid transparent;
    border-radius: 16px;
    @include setProperty(background, var(--dark-bg), var(--light-bg));
    background-clip: content-box !important;

    &::before {
      content: '';
      z-index: -1;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      position: absolute;
      @include setProperty(background, linear-gradient(90deg, rgba(247, 255, 254, 0) -33.37%, rgba(155, 188, 183, 0.204) 35.32%, rgba(247, 255, 254, 0) 118.57%), linear-gradient(261.67deg, rgba(81, 81, 81, 0.03) -3.47%, rgba(188, 188, 188, 0.3) 57.43%, rgba(81, 81, 81, 0.03) 107.73%));
      border-radius: inherit;
    }

    &::after {
      content: "";
      position: absolute;
      @include setProperty(
        background,
        linear-gradient(
          180deg,
          rgba(246, 255, 254, 0),
          rgba(246, 255, 254, 0),
          rgba(107, 216, 199, 0.03)
        ),
        linear-gradient(
          180deg,
          rgba(81, 81, 81, 0),
          rgba(81, 81, 81, 0),
          rgba(60, 191, 166, 0.03)
        )
      );
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }

    @include media(notMobile) {
      grid-template-columns: repeat(4, 1fr);
      margin: 96px auto 0;
    }

    @include media(desktopAll) {
      max-width: 1040px;
      margin: 96px auto 0;
      grid-auto-rows: 130px;
    }

    .fact {
      position: relative;
      z-index: 2;
      display: flex;
      margin: -1px 0;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include setProperty(color, var(--secondary-50), var(--secondary-500));
      @include setProperty(
        border-color,
        rgba(155, 188, 183, 0.1) !important,
        rgba(188, 188, 188, 0.15) !important
      );

      &:nth-child(n + 3) {
        border-top: 1px solid;
      }

      &:nth-child(odd) {
        border-right: 1px solid;
      }

      @include media(notMobile) {
        border-right: 1px solid;

        &:nth-child(n + 3) {
          border-top: none;
        }

        &:last-child {
          border-right: none;
        }
      }

      @include media(desktopAll) {
        gap: 8px;
      }

      &__title {
        font-family: "Montserrat", sans-serif;
        font-weight: 300;
        line-height: 28px;
        font-size: 14px;

        @include media(desktopAll) {
          font-size: 16px;
          line-height: 32px;
        }
      }

      &__value {
        font-family: "KharkivTone", sans-serif;
        line-height: 32px;
        font-size: 24px;

        @include media(desktopAll) {
          font-size: 32px;
          line-height: 40px;
        }
      }
    }
  }
}
