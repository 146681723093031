@import "src/styles/helpers";

.service {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media(landscape) {
    flex-direction: row;
    gap: 80px;
  }

  @include media(desktopAll) {
    flex-direction: row;
    gap: 120px;
  }

  .info {
    flex: 1 0;

    .title {
      font-family: "KharkivTone", sans-serif;
      font-size: 24px;
      line-height: 32px;

      @include media(desktopAll) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .description {
      margin-top: 16px;
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 28px;
      @include setProperty(color, var(--secondary-50), var(--secondary-500));

      @include media(desktopAll) {
        font-size: 16px;
        line-height: 32px;
      }
    }

    .viewButton {
      margin-top: 22px;
      margin-left: -24px;
      color: var(--primary-500) !important;
    }
  }

  .links {
    flex: 1 0;
    font-family: "KharkivTone", sans-serif;
    font-size: 14px;
    line-height: 20px;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));

    @include media(landscape) {
      margin-top: -28px;
    }

    @include media(desktopAll) {
      font-size: 16px;
      margin-top: -28px;
    }

    li {
      border-bottom: 1px solid;
      @include setProperty(
        border-color,
        var(--dark-line-low-accent),
        var(--light-line-low-accent)
      );

      &:last-child {
        border-bottom: none;
      }

      .link {
        height: 76px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: color 0.3s;

        &:hover {
          @include setProperty(color, var(--primary-500), var(--primary-900));
        }

        @include media(desktopAll) {
          height: 80px;
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
