@import "src/styles/helpers";

.textReviewCard {
  padding: 24px;
  margin: 64px 0;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 16px;
  @include setProperty(
    border-color,
    var(--dark-line-shape),
    var(--light-line-shape)
  );
  @include setProperty(
    background,
    linear-gradient(
      180deg,
      rgba(246, 255, 254, 0),
      rgba(246, 255, 254, 0),
      rgba(155, 188, 183, 0.05)
    ),
    linear-gradient(
      180deg,
      rgba(81, 81, 81, 0),
      rgba(81, 81, 81, 0),
      rgba(60, 191, 166, 0.05)
    )
  );
  @include setProperty(color, var(--secondary-50), var(--secondary-500));

  @include media(portrait) {
    margin: 104px 0;
    padding: 32px;
  }

  @include media(landscape) {
    margin: 120px 0;
    padding: 48px;
  }

  @include media(desktopAll) {
    margin: 88px 0;
  }

  @include media(desktopSmall) {
    padding: 64px;
  }

  @include media(desktop) {
    padding: 64px;
  }

  @include media(desktopBig) {
    padding: 72px;
  }

  @include media(desktopHuge) {
    padding: 72px;
  }

  .container {
    display: flex;
    flex-direction: column;

    @include media(landscape) {
      flex-direction: row;
      gap: 40px;
    }

    @include media(desktopAll) {
      flex-direction: row;
    }

    @include media(desktopSmall) {
      gap: 24px;
    }

    @include media(desktop) {
      gap: 104px;
    }

    @include media(desktopBig) {
      gap: 104px;
    }

    @include media(desktopHuge) {
      gap: 104px;
    }

    .avatar {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-shrink: 0;

      @include media(landscape) {
        text-align: center;
        flex-direction: column;
        order: 1;
      }

      @include media(desktopAll) {
        text-align: center;
        flex-direction: column;
        order: 1;
      }

      .avatarImage {
        position: relative;
        height: 72px;
        width: 72px;

        @include media(landscape) {
          height: 84px;
          width: 84px;
        }

        @include media(desktopAll) {
          height: 84px;
          width: 84px;
        }

        img {
          border-radius: 50%;
        }
      }

      .name {
        font-family: "KharkivTone", sans-serif;
        font-size: 14px;
        line-height: 20px;
      }

      .position {
        margin-top: 8px;
        font-family: Montserrat, sans-serif;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;

        @include media(landscape) {
          margin-top: 8px;
        }

        @include media(desktopAll) {
          margin-top: 8px;
        }
      }
    }

    .review {
      margin-top: 32px;
      @include setProperty(color, var(--secondary-50), var(--secondary-500));

      @include media(portrait) {
        margin-top: 40px;
      }

      @include media(landscape) {
        margin-top: 0;
      }

      @include media(desktopAll) {
        margin-top: 0;
      }

      .title {
        font-family: "KharkivTone", sans-serif;
        font-size: 24px;
        line-height: 32px;
        text-transform: none;

        @include media(landscape) {
          font-size: 28px;
          line-height: 36px;
        }

        @include media(desktopAll) {
          font-size: 40px;
          line-height: 48px;
        }
      }

      .text {
        margin-top: 24px;
        font-family: "KharkivTone", sans-serif;
        font-size: 14px;
        line-height: 20px;

        @include media(desktopAll) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  .button {
    margin: 40px auto 0;

    @include media(tablet) {
      margin: 32px 0 0;
    }

    @include media(desktopAll) {
      margin: 32px 0 0;
    }
  }
}
