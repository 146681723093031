@import "src/styles/helpers";

@mixin overlay($backdropBlur: 0) {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur($backdropBlur);
  @include setProperty(
    background,
    rgba(25, 32, 31, 0.05),
    rgba(246, 246, 246, 0.01)
  );
}

.hero__images {
  pointer-events: none;

  &__overlay {
    @include overlay(62.5px);

    height: calc(100% + 200px);
  }

  .vector {
    position: absolute;
    z-index: 2;

    &_blur {
      &_left {
        background: url("/media/industry_hero_ellipse_left_dark.svg") no-repeat
          center;
        width: 830px;
        height: 79px;
        right: 30vw;
        bottom: 240px;
        z-index: 0;

        &.hidden {
          display: none;
        }

        @include media(mobile) {
          display: none;
        }

        @include media(portrait) {
          display: none;
        }

        @include media(tablet) {
          bottom: 280px;
          right: 55vw;
        }

        @include media(desktopAll) {
          right: 65vw;
        }

        @include media(desktopBig) {
          right: 65vw;
        }
      }

      &_right {
        background: url("/media/industry_hero_ellipse_right_dark.svg") no-repeat
          center;
        width: 1067px;
        height: 109px;
        z-index: 0;
        display: none;

        &.hidden {
          display: none;
        }

        @include media(portrait) {
          display: none;
        }

        @include media(tablet) {
          top: 160px;
          display: block;
          left: 65vw;
        }

        @include media(desktopAll) {
          top: 210px;
          display: block;
          left: 60vw;
        }

        @include media(desktopBig) {
          left: 60vw;
        }

        @include media(desktopHuge) {
          left: 70vw;
        }
      }
    }

    &_left {
      width: 878px;
      height: 633px;
      right: calc(65vw + 60px);
      bottom: 100px;

      @include media(mobile) {
        display: none;
      }

      @include media(desktopBig) {
        right: 65vw;
      }
    }

    &_center {
      width: 120px;
      height: 95px;
      left: calc(50vw + 10px);
      top: 50%;
    }

    &_right {
      width: 683px;
      height: 388px;
      top: 20px;
      left: calc(75vw + 40px);

      @include media(mobile) {
        display: none;
      }

      @include media(portrait) {
        left: 85vw;
      }

      @include media(landscape) {
        left: 85vw;
      }

      @include media(tablet) {
        top: 80px;
      }

      @include media(desktopAll) {
        left: 70vw;
        top: 80px;
      }

      @include media(desktop) {
        left: calc(75vw);
      }

      @include media(desktopBig) {
        left: 70vw;
      }
    }
  }
}
